@use 'sass:math';
@use 'sass:list';
@use 'sass:color';
@import '~@insidedesk/tuxedo/dist/styles';


$indices: 1 2 3 4 5;
$progression-dollar:
  #ddefe7,
  #9dd1bb,
  #59b68f,
  #fdbf6f,
  #ec605b;
$progression-claim:
  #a6cee37f,
  #1f78b47f,
  #1f78b4b2,
  #fdbf6f,
  #ec605b;

$palette: (
  claimDark: #3674a2,
  claimLight: #cde1ec,
  dayDark: #cacaca,
  dayLight: #c994df,
  dollarDark: list.nth($progression-dollar, 3),
  dollarLight: list.nth($progression-dollar, 2),
  percentDark: #702277,
  percentLight: #c994df,
  workDark: #702277,
  workLight: #70227780,
);

/* Ideally would be setting fonts via highcharts style options but there is a
 * bug preventing that for now
 * https://github.com/highcharts/highcharts/issues/17917
 */
.highcharts-title {
  font-size: 18px !important;
}

.highcharts-legend,
.highcharts-label,
.highcharts-tooltip,
.highcharts-axis-title,
.highcharts-axis-labels {
  font-size: 14px !important;
  text,
  tspan {
    font-size: 14px !important;
  }
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
}


// XXX: useful to kep for debugging since it's hard to find the
// correct dimensions.
/*
#printWindow {
  top: 60px !important;
  left: 0 !important;
  width: 1060px !important;
  height: 816px !important;
  display: block;
}

.print {
  padding: 7px;
  background-color: transparent;
}
*/

@media print {
  @page {
    size: $page-width $page-width * math.div(9, 16);
  }
  .print {
    padding: 30px;
    background-color: transparent;
  }
}

:export {
  chartTitleColor: #495058;
  @each $index, $value in list.zip($indices, $progression-dollar) {
    progressionDollar#{$index}: #{$value};
  }
  @each $index, $value in list.zip($indices, $progression-claim) {
    progressionClaim#{$index}: #{$value};
  }
  @each $key, $value in $palette {
    palette#{unquote(capitalize($key))}: #{$value};
  }
}
